import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";

import { ObservedQueryProvider } from "hooks/useObservedQuery";
import useWindowSize from "hooks/useWindowResize";

import { Page } from "./types";
import Loading from "components/Loading/Loading";
// import { ExtendedWarranty } from "containers/ExtendedWarranty";

// lazy load all the pages
// const Loading = lazy(() => import("components/Loading/Loading"));
const Footer = lazy(() => import("shared/Footer/Footer"));
const PageContact = lazy(() => import("containers/PageContact/PageContact"));
const Page404 = lazy(() => import("containers/Page404/Page404"));
const PageHome2 = lazy(() => import("containers/PageHome/PageHome2"));
const SiteHeader = lazy(() => import("containers/SiteHeader"));
const FooterNav = lazy(() => import("components/FooterNav"));
const CarsComponent = lazy(() => import("containers/ListingCarPage/CarsComponent"));
const CarProfile = lazy(() => import("containers/ListingCarPage/CarProfilePage"));
const BoardPage = lazy(() => import("containers/BoardPage/BoardPage").then(module => ({ default: module.BoardPage })));
const StoryPage = lazy(() => import("containers/StoryPage/StoryPage").then(module => ({ default: module.StoryPage })));
const PrivacyPolicy = lazy(() => import("containers/LegalPages/PrivacyPolicy").then(module => ({ default: module.PrivacyPolicy })));
const FAQ = lazy(() => import("containers/LegalPages/FAQ").then(module => ({ default: module.FAQ })));
const TermsConditions = lazy(() => import("containers/LegalPages/TermsConditions").then(module => ({ default: module.TermsConditions })));
const Cookies = lazy(() => import("containers/LegalPages/Cookies").then(module => ({ default: module.Cookies })));
const FinanceDisclosure = lazy(() => import("containers/LegalPages/FinanceDisclosure").then(module => ({ default: module.FinanceDisclosure })));
const ModernSlavery = lazy(() => import("containers/LegalPages/ModernSlavery").then(module => ({ default: module.ModernSlavery })));
const Complaints = lazy(() => import("containers/LegalPages/Complaints").then(module => ({ default: module.Complaints })));
const VIPCollection = lazy(() => import("containers/VIPCollection").then(module => ({ default: module.VIPCollection })));
const SellCarPage = lazy(() => import("containers/SellCarPage/SellCarPage").then(module => ({ default: module.SellCarPage })));
const Insurance = lazy(() => import("containers/Insurance").then(module => ({ default: module.Insurance })));
const ExtendedWarranty = lazy(() => import("containers/ExtendedWarranty").then(module => ({ default: module.ExtendedWarranty })));
// const ServicePage = lazy(() => import("containers/ServicesPage/ServicePage").then(module => ({ default: module.ServicePage })));
// Other components
const AnalyticsListener = lazy(() => import("./HSAnalytics"));
const ScrollToTop = lazy(() => import("./ScrollToTop"));

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome2 },
  { path: "/#", exact: true, component: PageHome2 },

  // Kesh - Custom Routes
  { path: "/cars", component: CarsComponent },
  { path: "/car/:carId", component: CarProfile },
  // { path: "/services", component: ServicePage },
  { path: "/board", component: BoardPage },
  { path: "/story", component: StoryPage },
  { path: "/contact", component: PageContact },
  { path: "/sell-my-car", component: SellCarPage },
  { path: "/insurance", component: Insurance },

  // Legal Pages
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/faq", component: FAQ },
  { path: "/terms-conditions", component: TermsConditions },
  { path: "/cookie-policy", component: Cookies },
  { path: "/finance-disclosure/", component: FinanceDisclosure },
  { path: "/modern-slavery-statement/", component: ModernSlavery },
  { path: "/complaints", component: Complaints },
  { path: "/vip-collection-experience", component: VIPCollection },
  // { path: "/extended-warranty", component: ExtendedWarranty },


  // 404 for any other route
  { path: "*", component: Page404 },
];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
    <BrowserRouter>
      <ScrollToTop /> {/* Scroll Restoration */}
      <ObservedQueryProvider>
        <Suspense fallback={
          <Loading bgColor="bg-custom-900" />
        }>
          <AnalyticsListener>
            <SiteHeader />
            <Routes>
              {pages.map(({ component, path }) => {
                const Component = component;
                return <Route key={path} element={<Suspense fallback={<Loading />} children={<Component />} />} path={path} />
              })}
              <Route element={<Page404 />} />
            </Routes>

            {/* Responsive mobile header */}
            {WIN_WIDTH < 768 && <FooterNav />}
            <Footer />
          </AnalyticsListener>
        </Suspense>
      </ObservedQueryProvider>
    </BrowserRouter>
  );
};

export default MyRoutes;
